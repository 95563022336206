import $, { event } from "jquery";
import { documentReady, elementReady } from "../ReadyListener";
import Cookies from 'js-cookie'

const COOKIE_SESSION_START="vs";
const COOKIE_SESSION_DURATION_CONVERSION_SENT="vsd";
const COOKIE_DESKTOP_CONVERSION_SENT="vsdsk";
const COOKIE_PROMISING_BROWSER_CONTEXT_CONVERSION_SENT="vsdpr";

window.googleAdsQueue = [];
window.isGoogleAdsInitialized = false;
window.googleAnalyticsQueue = [];
window.isGoogleAnalyticsInitialized = false;

export function initGoogle({initAds, initAnalytics}) {

    window.initAds = initAds;
    window.initAnalytics = initAnalytics;

    if(!initAds && !initAnalytics) {
        return;
    }

    const code = `
    <!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://ts.loginfactor.com/actitm.xs?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-N38N45LF');</script>
    <!-- End Google Tag Manager -->
    `;
    $(document.body).append(code);


    if(initAds) {
        sendGTMEvent("initAdsOptin");
    }

    if(initAnalytics) {
        sendGTMEvent("initAnalyticsOptin");
    }

    initMehrAls10SekundenTracking();
    initDesktopClientTracking();
    initPromisingBrowserContextTracking();
    setInterval(initMehrAls10SekundenTracking, 1000*10);
    //setInterval(initDesktopClientTracking, 1000*2);
    //setInterval(initPromisingBrowserContextTracking, 1000*2);
}

function hasSessionDurationConversionBeenSent() {
    let session_duration_conversion_sent = Cookies.get(COOKIE_SESSION_DURATION_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    return !!session_duration_conversion_sent;
}

export function initMehrAls10SekundenTracking()  {
    /* Session Dauer tracken für 10-Minuten Conversion */
    let session_start = Cookies.get(COOKIE_SESSION_START, { domain: window.appConfig.COOKIE_DOMAIN });
    let session_duration_conversion_sent = hasSessionDurationConversionBeenSent();
    if(!session_start) {
        Cookies.set(COOKIE_SESSION_START, new Date().getTime(), { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
    } else {
        let session_age = new Date().getTime() - session_start;
        if(!session_duration_conversion_sent && session_age > 10) {
            Cookies.set(COOKIE_SESSION_DURATION_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
            sendConversionMehrAlsXSekundenAktiv();
        }
    }
}


export function initDesktopClientTracking()  {
    let isDesktopClient = isDesktopUser();
    let conv_sent = Cookies.get(COOKIE_DESKTOP_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    if(!conv_sent && isDesktopClient) {
        Cookies.set(COOKIE_DESKTOP_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
        sendConversionDesktopClient();
    }
}


export function initPromisingBrowserContextTracking()  {
    const is_apple = isAppleUser();
    const is_edge = isEdgeUser();
    const has_features_disabled = hasFeaturesDisabled();

    const isPromisingBrowserContext = is_apple || (is_edge && has_features_disabled);
    
    let conv_sent = Cookies.get(COOKIE_PROMISING_BROWSER_CONTEXT_CONVERSION_SENT, { domain: window.appConfig.COOKIE_DOMAIN });
    if(!conv_sent && isPromisingBrowserContext) {
        Cookies.set(COOKIE_PROMISING_BROWSER_CONTEXT_CONVERSION_SENT, '1', { secure: false, sameSite: 'lax', domain: window.appConfig.COOKIE_DOMAIN });
        sendPromisingBrowserContext();
    }
}

function sendGTMEvent(eventName, callback) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': "act_"+eventName,
        'eventCallback' : function() {
            try {
                if(callback) {
                    callback();
                }
            } catch(e) {
                console.error(e);
            }
        },
        'eventTimeout': 2000
    });
}

export const sendConversionNewsletterAngemeldet = (callback)=>sendGTMEvent("NewsletterAngemeldet", callback);
export const sendConversionKontaktformularAbgeschickt = (callback)=>sendGTMEvent("KontaktformularAbgeschickt", callback);
export const sendConversionTerminanfrageGeklickt = (callback)=>sendGTMEvent("TerminanfrageGeklickt", callback);
export const sendConversionMehrAlsXSekundenAktiv = (callback)=>sendGTMEvent("MehrAlsXSekundenAktiv", callback);
export const sendConversionCaseStudyFormGeoeffnet = (callback)=>sendGTMEvent("CaseStudyFormGeoeffnet", callback);
export const sendConversionCaseStudyFormAbgeschickt = (callback)=>sendGTMEvent("CaseStudyFormAbgeschickt", callback);
export const sendLeistungsuebersichtAngefordert = (callback)=>sendGTMEvent("LeistungsuebersichtAngefordert", callback);
export const sendRueckrufAngefordert = (callback)=>sendGTMEvent("RueckrufAngefordert", callback);
export const sendSaaSOrderStart = (callback)=>sendGTMEvent("SaaSOrderStart", callback);
export const sendSaaSOrderSuccess = (callback)=>sendGTMEvent("SaaSOrderSuccess", callback);
export const sendConversionDesktopClient = (callback)=>sendGTMEvent("DesktopClient", callback);
export const sendPromisingBrowserContext = (callback)=>sendGTMEvent("PromisingBrowserContext", callback);

elementReady((root)=>{
    const hints = root.findWithRoot('[data-conversion-hint]').filter(":visible");
    hints.each((i,el)=>{
        if($(el).data("conversion-hint")=="newsletter_success") {
            sendConversionNewsletterAngemeldet();
        }
        if($(el).data("conversion-hint")=="contact_success") {
            sendConversionKontaktformularAbgeschickt();
        }
        if($(el).data("conversion-hint")=="case_study_form") {
            sendConversionCaseStudyFormGeoeffnet();
        }
        if($(el).data("conversion-hint")=="case_study_success") {
            sendConversionCaseStudyFormAbgeschickt();
        }
        if($(el).data("conversion-hint")=="leistungsuebersicht_angefordert") {
            sendLeistungsuebersichtAngefordert();
        }
        if($(el).data("conversion-hint")=="rueckruf_angefordert") {
            sendRueckrufAngefordert();
        }
        if($(el).data("conversion-hint")=="saas_order_start") {
            sendSaaSOrderStart();
        }
        if($(el).data("conversion-hint")=="saas_order_success") {
            sendSaaSOrderSuccess();
        }
        
    })
})

documentReady((root)=>{
    $("body").on('click', '[data-onclick-google-ads-hint]', function(ev) {
        const hint = $(this).data("onclick-google-ads-hint");

        ev.preventDefault();
        ev.stopPropagation();
        
        const continue_click = ()=>{
            setTimeout(()=>{
                window.location.href = $(this).attr("href");
            },500)
        }
        const force_continue_click = ()=>{
            setTimeout(()=>{
                window.location.href = $(this).attr("href");
            },1000)
        }
        force_continue_click(); // Falls GTAG nicht initialisiert ist oder geblockt wird, wollen wir trotzdem weitermachen....

        if(hint == "booking") {
            sendConversionTerminanfrageGeklickt(()=>{continue_click();});
        } else {
            continue_click();
        }

        return false;
    });

});

function isEdgeUser() {
    const isEdgeUser = /Edg/.test(navigator.userAgent);
    //console.log("isEdgeUser", isEdgeUser);
    return isEdgeUser;
}

function isAppleUser() {
    
    var platform = navigator.platform || 'unknown';
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Überprüfung auf iOS-Geräte
    var isiOS = /iPad|iPhone|iPod/.test(userAgent) ||
                (platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    // Überprüfung auf macOS-Geräte
    var isMacOS = platform === 'MacIntel' && navigator.maxTouchPoints === 0;

    const isAppleUser = isiOS || isMacOS;
    //console.log("isAppleUser", isAppleUser);
    return isAppleUser;
}

function hasFeaturesDisabled() {
    // Überprüfen, ob bestimmte JavaScript-Funktionen deaktiviert sind
    var featuresDisabled = false;
    
    // Überprüfung von Local Storage
    try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
    } catch (e) {
        featuresDisabled = true;
    }
    
    // Überprüfung von Session Storage
    try {
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
    } catch (e) {
        featuresDisabled = true;
    }
    
    // Überprüfung von Cookies
    if (!navigator.cookieEnabled) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Clipboard API
    if (!navigator.clipboard) {
        featuresDisabled = true;
    }
    
    // Überprüfung der WebGL-Unterstützung
    var hasWebGL = (function() {
        try {
        var canvas = document.createElement('canvas');
        return !!window.WebGLRenderingContext && (
            canvas.getContext('webgl') ||
            canvas.getContext('experimental-webgl')
        );
        } catch (e) {
        return false;
        }
    })();
    
    if (!hasWebGL) {
        featuresDisabled = true;
    }
    
    // Überprüfung der WebRTC-Unterstützung
    var hasWebRTC = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    
    if (!hasWebRTC) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Geolocation API
    if (!('geolocation' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Notifications API
    if (!('Notification' in window)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Battery Status API
    if (!('getBattery' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Device Memory API
    if (!('deviceMemory' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung der Network Information API
    if (!('connection' in navigator)) {
        featuresDisabled = true;
    }
    
    // Überprüfung von Media Queries mit matchMedia
    var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    var prefersColorSchemeDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // Wenn bestimmte Media Features nicht verfügbar sind
    if (typeof prefersReducedMotion !== 'boolean' || typeof prefersColorSchemeDark !== 'boolean') {
        featuresDisabled = true;
    }
    
    // Ergebnis zurückgeben
    //console.log("hasFeaturesDisabled", featuresDisabled);
    return featuresDisabled;
        
}

function isDesktopUser() {
    const isDesktopUser = window.matchMedia("(min-width: 769px)").matches;
    //console.log("isDesktopUser", isDesktopUser);
    return isDesktopUser;
}